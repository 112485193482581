@import '_variables.scss';

.site-information-report-messages {
  background-color: white;
  color: $BrightRed;
  font-weight: 600;
}

.site-information-report-error a {
  font-weight: 600;
  color: #00aeda;
}

.site-information-report-combobox  {
  width: 250px;
}

.sir-map-checkbox {
	display: flex;
	flex-wrap: wrap;
	.flex-align{
		flex: 1 0 auto;	
	}
}