@import '_variables.scss';

.data-attribution {
	.data-attribution-terms {
		background-color: inherit;
		border: 1px solid $SecondaryGrayLight;
		border-radius: inherit;
		font-size: inherit;
		font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
		white-space: pre-line;
		word-break: break-word;
		max-height: 250px;

		&.standalone {
			max-height: 100%;
			border: inherit;
		}
	}

	.logos > img {
		height: auto;
		max-width: 95%;
		margin-bottom: 25px;
	}
}