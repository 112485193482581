.tou{
  .tou-terms{
    height: 250px;
    overflow-y: scroll;
    border: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
  }
  .tou-text{
    margin-bottom: 10px;
  }
}