@import '_variables.scss';


.property-compare {
   .compSetLightBackground {
     background-color: $AnotherLightGrey; 
     padding: 35px;   
   }

   .padding-top-row {
     @media (min-width: 768px) {
       padding-top: 35px;
     }
    }

   .export-links {
     color: $PrimaryBlueDark;
   }
  .pcr-map-checkbox {
    display: flex;
    flex-wrap: wrap;
    .flex-align{
      flex: 1 0 auto; 
    }
  }
}




