@import '_variables.scss';

.usage-capi {
  .filter-section {
    background-color: $SecondaryWhite;
    border: 1px solid #ddd;
    border-top: 0;
    padding: 25px 35px;
  }

  .grid-buttons {
    z-index: 1;
    margin-top: 3%;
    margin-bottom: 5px;
  }
  .k-datepicker {
    width: 100%;
  }

  .k-grid table {
    table-layout: fixed;
  }

  .k-grid td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .txt-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
