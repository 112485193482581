@mixin transparent-button {
	border:transparent;
	background: transparent;
	color: white;
	vertical-align: middle;
	text-align: center;
	display: inline-block;
	font-size: 16px;
	line-height: 32px;
	text-decoration:none;
}