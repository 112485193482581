@import '_variables.scss';

.supportPage {
  .linkContainer {
    display: inline-block;
    text-align: center;
    margin: 25px 25px 0px 0px;

    :hover {
      color: white;
      background-color: $PrimaryBlueDark;
    }
    a {
      text-decoration: none;
    }
  }

  .link {
    outline: 1px solid $PrimaryBlueDark;
    width: 250px;
    height: 250px;
    padding-top: 25%;
  }
}

.phone-height-adjust {
  position: relative;
  top: -1px;

  .k-textbox {
    height: 35px;
  }
}

.back-to-support-btn {
  margin-top: 45px;
  border-radius: 0;
  background-color: $PrimaryBlueDark;
  color: white;
}

button.btn.back-to-support-btn.ng-scope:hover {
  color: white;
}

button.btn.back-to-support-btn.ng-scope:focus {
  color: white;
}

.support-pages-header {
  margin-top: 35px;
  margin-left: 13px;
}

.support-margin-text {
  position: relative;
  left: -14px;
  margin-top: 20px;
  font-family: $FontFamily;
  font-weight: 600;
}

.support-library-desc-text {
  margin-top: 20px;
  font-family: $FontFamily;
  font-weight: 600;
  margin-bottom: 10px;
}

.support-line-height {
  line-height: 22px;
}

.support-bottom-align-text {
  position: absolute;
  bottom: 0;
  right: 0;
}

.support-position-increase {
  position: relative;
  top: -75px;
}

.divTable {
  display: table;
  width: 100%;
}

.divTableRow {
  display: table-row;
}

.divTableCell-term, .divTableHead-term {
  border-bottom: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
  width: 20%;
}

.divTableCell-def, .divTableHead-def {
  border-bottom: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;

  :hover {
    text-decoration: none;
  }
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
}

.faqHeader {
  font-size: 27px;
  margin: 20px;
}

.panel-heading [data-toggle="collapse"]:after {
  font-family: 'Glyphicons Halflings';
  content: "\e072"; /* "play" icon */
  float: right;
  color: $PrimaryBlueDark;
  font-size: 18px;
  line-height: 22px;
  /* rotate "play" icon from > (right arrow) to down arrow */
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.panel-heading [data-toggle="collapse"].collapsed:after {
  /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #454444;
}

.accordion-toggle:focus {
  outline: none;
}
