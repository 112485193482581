@import '../_variables.scss';

.map-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: margin-left .3s;

  .map-top {
    height: 100px;
    position: relative;

    h2 {
      margin: 20px 0 20px 70px;
    }

    #displaySaveCtrls {
      position: absolute;
      right: 0px;
      margin-top: -50px;
      padding-right: 35px;
    }
  }

  .MicrosoftMap .OverlaysTL {
    left: 20px !important;
  }

  .map-bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 auto;
    flex-direction: column;
    width: 100%;

    .map-bottom-left-half {
      display: flex;
      -ms-flex: 1 1;
      flex: 1 1;
      flex-direction: column;
      position: absolute;
      top: 105px;
      bottom: 0;
      width: $LocalSidebarHalfWidth;
      z-index: 500;
      background-color: white;
      overflow-y: hidden;
      overflow-x: hidden;    
      
      ::webkit-scrollbar {
        margin-left: -20px;
      }
    }

    #toggle-button-half {
      cursor: pointer;
      color: $PrimaryBlueDark;
      background-color: $PrimaryWhite;
      font-size: .7em;
      width: 20px;
      height: 40px;
      position: absolute;
      z-index: $MapZoomButtonsZIndex;
      //margin-left: 367px;
      span {
        margin-top: 13px;
        margin-left: 3px;
      }
    }

    #toggle-button-half:hover {
      color: $PrimaryWhite;
      background-color: $PrimaryBlueDark;
    }

    .moved-down {
      top: 177px;
    }

    .moved-up {
      top: 105px;
    }
    // Property details drop down toggle
    .prop-drop-toggle {
      background-color: $PrimaryWhite;
      cursor: pointer;
      width: 10px;
      height: 10px;
      line-height: 0 !important;
      text-align: center;
      border: 1px solid $SecondaryGrayLight;
      color: lighten($PrimaryGrayDark, 20);
      position: relative;
      left: 260px;
      top: 1.5em;
      padding: 6px;
      z-index: $MapOverlayZIndex;

      #plus {
        margin-left: -4px;
        font-size: larger;
      }

      #minus {
        margin-left: -2px;
      }
    }

    .map-container {
      position: absolute;
      flex: 1 1;
      left: 0;
      right: 0;
      top: 105px;
      bottom: 0;

      bing-map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        a {
          img {
            position: absolute;
            left: -10px;
            height: 50px;
            width: 50px;
          }
        }
      }

      #toggle-button-full {
        z-index: $MapZoomButtonsZIndex;
        cursor: pointer;
        color: $PrimaryBlueDark;
        background-color: $PrimaryWhite;
        font-size: .7em;
        width: 20px;
        height: 40px;
        position: absolute;
        left: 95%;

        span {
          margin-top: 13px;
          margin-left: 3px;
        }
      }

      #toggle-button-full:hover {
        color: $PrimaryWhite;
        background-color: $PrimaryBlueDark;
      }
    }
  }

  .color-label {
    float: left;
    width: 10px;
    height: 10px;
    margin: 5px;
    border: 0.5px solid $SecondaryGrayLight;
    &.radius1 {
      background-color: rgba(0,51,102,0.6); 
    }
    &.radius2 {
      background: rgba(0,51,102,0.3);
    }
  }

  .color-label-med-right {
    float: right;
    width: 12px;
    height: 12px;
    margin: 5px;
    border: 0.5px solid $SecondaryGrayLight;
    &.radius1 {
      background-color: rgba(0,51,102,0.6); 
    }
    &.radius2 {
      background: rgba(0,51,102,0.3);
    }
  }

  #prop-title {
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;

    label {
      width: 75%;
      word-wrap: normal;
      line-height: 1;
      margin-left: 5px;
      margin-top: 8px;

      a {
        color: lighten($PrimaryBlueDark, 30);
      }
    }

    span {
      align-self: center;
      margin-top: 3px;
    }

    input {
      margin-top: 8px;
    }

    .pin-num {
      align-self: flex-start;
      margin-top: 15px !important;
    }
  }

  .bing-map-messages {
    position: absolute;
    z-index: 10;
    background-color: white;
    border: 1px solid $PrimaryBlueDark;
    width: 500px;
    margin-left: -250px;
    left: 50%;
  }

  .bing-map-messages.bing-map-error {
    top: 21px;
  }

  .bing-map-legend {
    position: absolute;
    z-index: $MapOverlayZIndex;
    background-color: white;
    width: 260px;
    right: 0px;
    bottom: 0px;

    img {
      height: 20px;
      width: 20px;
    }

    .pin-legend-wrapper{
      display: flex;
    }

    #pin-legend {
      flex: 1 0 auto;
      line-height: 40px;
    }

    .legend-arrow {
      flex: 0 0 auto;
      align-self: center;
      line-height: 40px;
      padding-left: 15px;
      padding-right: 15px;
      top: 0px;
      transition: background-color 200ms;
      color: $PrimaryBlueDark;

      &:hover {
        background-color: darken($PrimaryWhite, 10);
      }
    }
  }

  .black-text div {
    color: black !important;
  }

  .map-type-dropdown {
    position: absolute;
    z-index: 5;
    padding-left: 10px;
    text-align: left;
    margin-top: 13px;
    right: 95px;

    .k-widget.k-dropdown.k-header {
      width: 110px;
    }

    .term {
      font-weight: 600;
      color: black;
    }
  }
}

.select-properties-list {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 40%;
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.property-counters {
  padding-top: 2px;
  min-width: 50%;
  color: gray;

  .property-count {
    height: 28px;
    font-style: italic;
  }
  .results {
    float: right;
  }
}

// filters
.filters {
  background-color: $LocalFiltersBackground;
  max-width: 101.05%;

  button, div, input {
    border-radius: 0;
    background-color: $LocalFiltersBackground;
    font-weight: bold;
    color: $PrimaryBlueDark;
    height: 32px;
    border: none;
  }

  .filter-drops, #advanced {
    border-right: 1px solid grey;
    height: 34px;

    .k-multiselect .k-button.ng-scope {
      top: 5px;
    }
  }

  div {
    padding: 0;
  }

  #address {
    margin-left: 15px;
    border-top: 1px solid $LocalFiltersBorder;
    max-width: 333px;

    input {
      height: 33px;
      background-color: white;
      border-bottom: 1px solid $LocalFiltersBorder;

      @media (max-width: 1155px) {
        font-size: .8em;
      }
    }
  }

  #search {
    width: 34px;
    background-color: $PrimaryWhite;

    button {
      background-color: $PrimaryGrayDark;
      border: none;
      color: $PrimaryWhite;
      padding-left: 10px;
      padding-top: 8px;
    }
  }

  #prop-types {
    min-width: 150px;
    cursor: pointer;

    * {
      cursor: pointer;
    }

    @media (max-width: 1200px) {
      max-width: 40px;
    }
  }

  .k-multiselect-wrap {
    & > .k-input {
      position: relative;
      left: 7px;
      top: 3px;
    }

    &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }

  #all-stats {
    min-width: 195px;
    cursor: pointer;

    * {
      cursor: pointer;
    }

    @media (max-width: 1200px) {
      max-width: 100px;
    }

    @media (max-width: 1105px) {
      max-width: 170px;
    }

    @media (max-width: 1075px) {
      max-width: 120px;
    }

    @media (max-width: 1020px) {
      max-width: 100px;
    }
  }

  #most-recent {
    width: 166px;

    button {
      border-right: 1px solid $LocalFiltersBorder;
    }
  }

  #advanced {
    width: 90px;

    button {
      width: 90px;
      border-right: 1px solid darken($LocalFiltersBorder, 25);
    }
  }

  #trade-area {
    width: 90px;

    button {
      width: 90px;
      border-right: 1px solid darken($LocalFiltersBorder, 25);
    }
  }

  #run-report {
    width: 190px;

    button {
      width: 190px;
      border-right: 1px solid darken($LocalFiltersBorder, 25);
      border-left: 1px solid darken($LocalFiltersBorder, 25);

      &.disabled {
        color: $PrimaryWhite;
        background-color: $LocalFiltersBorder;
        border-right: 0;
        border-left: 0;
      }
    }
  }

  #save-search, #reset {
    border: none !important;
    width: 60px;
  }

  .save-search-reset {
    position: absolute;
    right: 0px;
  }

  #save-search {
    width: 100px;

    @media(max-width: 1120px) {
      width: 80px;
    }

    & .disabled {
      color: $PrimaryWhite;
      background-color: $LocalFiltersBorder;
      border-right: 0;
      border-left: 0;
    }
  }
}

.highlighted {
  background-color: rgba(10,175,220,.2);
}

.square-corners {
  border-radius: 0;
  left: -2px;
}

.fixed-scroll {
  -ms-flex: 0 1;
  flex: 0 1;
  background-color: $PrimaryWhite;
  z-index: 11;
}

/*
* Flexbox properties
*/

.flxbx {
  padding: 10px 30px 10px 30px;
  display: -ms-flexbox;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  border-bottom: 1px solid $SecondaryGrayLight;

  label {
    width: 180px;
  }

  #flxbx-address {
    width: 180px;
    line-height: .8;
  }

  #sale-date {
    width: 120px;
  }

  #sales-price {
    width: 140px;
  }

  label, div {
    padding: 5px;
  }

  #city {
    align-self: flex-end;
  }

  #total-unit-count {
    order: -1;
  }

  #operator {
    width: 135px;
  }

  .first-order {
    order: -2;
  }
}

.flxbx-half-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1;
  flex: 1 1;
  border-top: solid 1px lighten(#AEBCBA, 20);
  color: $PrimaryBlueDark;
  padding-top: 15px;
  padding-bottom: 8px;


}



.flxbx-half-exp-end {
  /*
    // IN IE11 this was causing the Export section to not show so getting rid of it for now.
    -ms-flex: 0 1;
    flex: 0 1;
  */
  bottom: 0;
  width: $LocalSidebarHalfWidth;
  height: 4.5em;
  background-color: $PrimaryWhite;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  span {
    font-size: x-small;
  }

  button {
    margin-top: 1px;
    padding-top: 3px;
    padding-bottom: 4px;
  }
}

#bot-half-lt {
  padding-top: 3px;
  font-size: 10pt;
  font-style: italic;
  color: $SecondaryGrayLight;
  background-color: $PrimaryWhite !important;
}

#bot-full-lt {
  margin-left: 30px;
  font-size: 10pt;
  font-style: italic;
  color: $SecondaryGrayLight;

  p {
    padding-left: 20px;
  }
}

.sort-by-half {
  width: 120px;
  top: -5px;
}

.sort-by {
  color: $PrimaryBlueDark;
  font-family: $FontFamily;
}

.select-properties-header {
  -ms-flex: 0 1;
  flex: 0 1;
  position: relative;
  width: $LocalSidebarHalfWidth;
  height: 32px;
  background-color: $LocalFiltersBackground;
  border-top: 1px solid $LocalFiltersBorder;
  border-bottom: 1px solid $LocalFiltersBorder;

  button {
    font-weight: 200;
    background-color: $LocalFiltersBackground;
    border: none;
    width: 100%;
    height: 100%;
  }
}

.prop-check-box {
  z-index: 1000;
}

#color-greenPin {
  color: $GreenPin;
  position: relative;
  top: 3px;
  font-weight: bold;
}

#color-darkGreyPin {
  color: $DarkGreyPin;
  position: relative;
  top: 3px;
  font-weight: bold;
}

#color-bluePin {
  color: $BluePin;
  position: relative;
  top: 3px;
  font-weight: bold;
}

#color-tealPin {
  color: $TealPin;
  position: relative;
  top: 3px;
  font-weight: bold;
}

#color-redPin {
  color: $RedPin;
  position: relative;
  top: 3px;
  font-weight: bold;
}

.legend-checkbox * {
  text-align: left !important;
}

// DRY classes

.drop-width {
  min-width: 150px;
}

// Map size changes
.half-open {
  left: $LocalSidebarHalfWidth !important;
}

.adv-button-blue {
  color: white !important;
  background-color: $PrimaryBlueDark !important;
}

.popover {
  border-radius: 0px;
  max-width: 450px;
}

.map-container .k-multiselect-wrap {
  &::before {
    bottom: 9px !important;
  }
}

.uib-datepicker-popup {
  z-index: $AdvancedSearchMenuZIndex+1
}

.saved-searches-butn {
  width: 180px;
  height: 30px;
  margin-right: 20px;
}

.saved-par-butn {
  width: 270px;
  height: 30px;
}

.hidden-until-later {
  display: none;
}

.edit-map-editting .MicrosoftMap {
  cursor: url(../../assets/map/pencil.ico) 12 45,auto !important;
}

#height-fix {
  position: relative;
  top: -1px;
}

//override the bing maps v8 drawing bar to always hide it.
.drawingBar {
  visibility: hidden;
}

//override the bing maps v8 Locate Me Buton to always hide it.
.LocateMeControl {
  visibility: hidden;
}
