@import '_variables.scss';

.infobox {
  padding: 10px 25px 25px 25px !important;
  background-color: white;
  border: 1px solid;
  border-bottom: 8px solid;
  border-color: black;
  min-height: 100px;
  width: 320px;

  &.plannedProperty {
    border-color: $SecondaryGrayLight;
  }

  &.hospital {
    border-color: $BluePin;
  }

  &.CCRC {
    border-color: $RedPin;
  }

  &.NC {
    border-color: $TealPin;
  }

  &.AL {
    border-color: $BluePin;
  }

  &.IL {
    border-color: $GreenPin;
  }

  &.MC {
    border-color: $DarkGreyPin;
  }

  &.cbsa,
  &.county {
    width: unset;
    border: 3px solid;
    border-radius: 5px;
    border-color: $ClusterPinGreen;
    padding: 10px !important;
    min-height: unset;

    &.no-access {
      border-color: $ClusterPinGrey;
      width: 330px;
    }
  }

  label {
    font-weight: normal;
  }

  .full-details {
    color: #142f53;
    font-weight: 600;
    cursor: pointer;
  }

  .addToComps {
    display: block;
    float: left;
    margin-right: 5px;
    white-space: nowrap;
  }

  .infobox-close {
    color: white !important;
    background-color: gray;
    z-index: 5 !important;
    border-radius: 100px;
    border: solid 2px white;
    width: 20px !important;
    height: 20px !important;
    right: -9px !important;
    top: -9px !important;
    box-shadow: 1px 1px 3px grey;
    line-height: 1;
    cursor: pointer;
    a {
      color: white !important;
    }
  }

  .checkbox-infobox {
    input {
      vertical-align: middle;
    }

    label span {
      vertical-align: middle;
    }
  }

  .type-code {
    padding-bottom: 10px;
    margin-left: -5px;
  }

  #ssiPopupInit {
    color: #142f53;
    font-weight: 600;
    position: relative;
    top: 10px;
    cursor: pointer;
  }

  .maxWidth80 {
    max-width: 80%;
  }

  .walkScoreImageDiv {
    position: absolute; 
    top: 10px; 
    right: 63px;
    > a img {
      width: 55px !important;
      height: 55px !important;
    }
  }
}
