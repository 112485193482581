$KendoLightGray: #cccccc;

$PrimaryBlueDark: #142f53;
$SecondaryBlueLight: #00aeda;
$PrimaryGrayDark: #797979;
$SecondaryGrayLight: #AEBCBA;
$PrimaryWhite: #FFFFFF;
$SecondaryWhite: #FBFBFB;
$HomePageBG: #ECF1F2;
$AnotherLightGrey: #efefef;
$DarkerGrey: #d3d0d0;
$DarkDarkerGrey: #666666;
$Black: #000000;

$ProtectionThresholdMet: #0CA015;
$ProtectionThresholdNotMet: #B3BFCE;

$ClusterPinGreen: rgba(20, 180, 20, 0.5);
$ClusterPinGrey: rgba(50, 50, 50, 0.5);

$PortfoliosBorder:$DarkerGrey;
$PortfoliosHeaderBackGround:$AnotherLightGrey;
$PortfoliosHeaderText:$PrimaryGrayDark;
$PortolioLinkColor:$SecondaryBlueLight;

$Footer: $PrimaryGrayDark;
$FooterBody: $PrimaryWhite;
$LoginActionBlue: $PrimaryBlueDark;
$UtilityNav: $PrimaryBlueDark;
$UtilityNavHover: $PrimaryGrayDark;
$UtilityNavBackground: $SecondaryWhite;
$LeftNav: $PrimaryBlueDark;
$LeftNavSelected: $SecondaryBlueLight;
$LeftNavHover:#04295D;
$LeftNavWidth: 260px;
$LeftNavText: $PrimaryWhite;
$LeftNavTextSize:16px;
$LeftNavBorder: #3c5168;
$TabToSpaces: 1em;

$SubscriptionBannerHeight:205px;

$DashboardPanelHeaderTextColor:$PrimaryBlueDark;
$DashboardPanelHeaderBorderColor: $HomePageBG;
$SearchLocalBackground: $PrimaryBlueDark;
$SearchLocalText: $PrimaryWhite;
$SearchLocalBorder: $SecondaryBlueLight;
$SocialLink: $PrimaryWhite;
$SocialLinkHover: $SecondaryGrayLight;
$QuickSearchBG: $SecondaryGrayLight;
$QuickSearchBorder:$HomePageBG;
$QuickSearchText:$PrimaryWhite;
$WebinarWidth:445px;
$WebinarBorder: $SecondaryGrayLight;
$ActiveSlideBubble: $PrimaryGrayDark;
$SlideBubble: $SecondaryGrayLight;
$SlidesControls: $PrimaryBlueDark;
$SlideImageWidth:390px;
$SlideImageHeight:292px;
$SlidePadBottom:47px;
$NewsTitleColor: $PrimaryBlueDark;
$NewsButtonColor: $PrimaryBlueDark;
$RecentActivityBodyTextSize:15px;
$LocalFiltersBackground: $AnotherLightGrey;
$LocalFiltersBorder: $DarkerGrey;

$FontFamily: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;

//Property Type Colors
$RedPin: #AF2027;
$TealPin: #00AEDA;
$BluePin: #084865;
$GreenPin: #106555;
$DarkGreyPin: #797979;
//Portfolio Comp Status
$BrightGreen: #00ff1e;
$BrightRed: #fb0909;

//Bing v8 ZIndexes
$MapOverlayZIndex: 1001; //1001 is the base for the bing buttons. To overlap them, add one
$MapZoomButtonsZIndex: $MapOverlayZIndex + 1; 
$AdvancedSearchMenuZIndex: $MapOverlayZIndex + 1;

$LocalSidebarHalfWidth: 367px;

//Bootstrap Media Breakpoints
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);
