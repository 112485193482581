@import '_variables.scss';
.portfolios-css {
  //bootstrap override
  .sub-header-bg {
    min-height: $SubscriptionBannerHeight;
  }
  .checkbox + .checkbox,
  .radio + .radio {
    margin-top: 10px;
  }
  .graph-header {
    z-index: 10;
  }
  .properties-selected {
    font-style: italic;
  }
  .portfolio-save {
    text-align: right;
    padding-left: 0;
    padding-right: 0;
    bottom: 7px;
  }
  .search {
    border: 1px solid $PortfoliosBorder;
    float: left;
    width: 100%;
    height: 30px;
    padding-left: 10px;
  }
  .portfolio-name {
    padding-left: 5px;
    width: 100%;
  }
  .left-section {
    border: 1px solid $PortfoliosBorder;
    .left-section-header {
      background-color: $PortfoliosHeaderBackGround;
      color: $PortfoliosHeaderText;
      font-weight: bold;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .portfolio-list {
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      height: 1240px; //height should extend to last chart
      .selectable-report {
        margin-left: 0px;
        .name {
          padding-bottom: 10px;
          padding-left: 15px;
          padding-right: 0px;
          padding-top: 10px;
          min-height: 40px;
          color: $PortolioLinkColor;
          text-decoration: none;
          font-weight: normal;
        }
        a {
          cursor: pointer;
          vertical-align: middle !important;
          &:hover {
            background-color: rgba(121,121,121,.1);
          }
        }
      }
      .selectable-portfolio {
        @media only screen and (min-width : 768px) {
          &.is-table-row {
            display: table;
            width: 100%;
            margin-left: 0px;
          }
          &.is-table-row [class*="col-"] {
            float: none;
            display: table-cell;
            vertical-align: top;
          }
        }
        a {
          vertical-align: middle !important;
          &:hover {
            background-color: rgba(121,121,121,.1);
          }
        }
        .name {
          padding-bottom: 10px;
          padding-left: 15px;
          padding-right: 0px;
          padding-top: 10px;
          min-height: 40px;
          color: $PortolioLinkColor;
          text-decoration: none;
          font-weight: normal;
        }
        img {
          height: 23px;
        }
        
      }
      .sub-portfolio {
        div {
          vertical-align: middle !important;
        }
        div > img {
          height: 20px;
          padding-bottom: 5px;
        }
      }
    }
    .portfolio-details {
      height: 400px;
    }
    .portfolio-list-details {
      height: 840px;
    }
  }

  .inline-image {
    height: 16px;
    width: 16px;
    vertical-align: text-bottom;
  }

  hr {
    margin-top: 40px;
  }
  .manage-portfolios-select {
    width: initial;
  }
  .manage-portfolio-buttons {
    padding-bottom: 40px;
    top: 20px;
  }

  .portfolios {
    padding-bottom: 23px;
  }

  .editPortfolio {
    input {
      border-radius: 0 !important;
    }
    .label-margin {
      margin-top: 10px;
    }
    .protections-not-met {
      color: $ProtectionThresholdNotMet;
      font-style: italic;
    }
    .protections-not-met::before {
      content: url("../../assets/portfolio/threshold_inactive.png");
      display: inline-block;
      height: 13px;
      width: 13px;
    }
    .protections-met {
      color: $ProtectionThresholdMet;
      font-style: italic;
    }
    .protections-met::before {
      content: url("../../assets/portfolio/threshold_active.png");
      display: inline-block;
      height: 13px;
      width: 13px;
    }
  }
  
  .manageCopy {
    background-image: url("../../assets/portfolio/copy.png");
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  
  .manageCopy:hover {
    background-image: url("../../assets/portfolio/copy-hover.png");
  }
  .manageDelete {
    background-image: url("../../assets/portfolio/delete.png");
    display: inline-block;
    width: 22px;
    height: 22px;
  }
  .manageDelete:hover {
    background-image: url("../../assets/portfolio/delete-hover.png");
  }
  .admin-Portfolio-Details {
    padding-top: 30px;
  }
  .suggested-Comp-Filters {
    input {
      border-radius: 0;
    }
    label {
      color: $DarkDarkerGrey;
      font-weight: normal;
    }
  }
  .portfolio-table {
    border: none;
    th {
      background-color: $PrimaryBlueDark;
      color: $PrimaryWhite;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: white;
      a {
        color: $PrimaryWhite !important;
      }
    }
    tr {
      border-right: 1px solid;
      border-bottom: 1px solid;
      border-color: #eee;
    }
    td {
      border-left: 1px solid;
      border-color: #eee;
      a {
        font-weight: 600;
        color: $SecondaryBlueLight;
      }
    }
  }
  .chart-container {
    position: relative;
    width: inherit;
  }
  .chart-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($PrimaryWhite,.7);      
    text-align: center;
    & > div {
      position: relative;
      font-size: 20px;
      margin-top: -17px;
      top: 50%;
      width: 90%;
      margin-left: 7.5%;
      color : $PrimaryBlueDark;
    }
  }
}
