@import '_variables.scss';

.property-advisor {
  .report-label {
    color: $PrimaryBlueDark;
  }

  .compSetLightBackground {
    background-color: $AnotherLightGrey;
    padding: 35px;
  }

  .pad-previous-row {
    @media (min-width: 768px) {
      padding-top: 20px;
    }
  }

  .makeBigger {
    height: 80px; //need to be bigger than neighboring H2 tag or else cells don't like up right.
  }

  .compSet {
    .k-grid .k-grid-header .k-header .k-link {
      height: auto;
    }
  }
  
  .propertyadvisor-map-checkbox {
    display: flex;
    flex-wrap: wrap;
    .flex-align{
      flex: 1 0 auto; 
    }
  }
  .propertyadvisor-map-section {
    position: relative;
    padding-bottom: 30%;
    margin-bottom: 40px;

    bing-map {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      a {
        img {
          position: absolute;
          left: -10px;
          height: 50px;
          width: 50px;
        }
      }
    }

    .bing-map-tou {
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }

  .closer-to-page-title {
    margin-top: -7px; //move this section a little closer to the page title.
  }

  .button-dropdown-align {
    top: 4px;
  }

  .report-name-label {
    text-align: right;
    clear: both;
    float: left;
    margin-right: 15px;
    line-height: 30px;
  }

  .report-name-width {
    width: 81%;
  }

  .report-red-border {
    border-color: $BrightRed;
  }

  hr {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
