@import '_variables.scss';

.videoLibrary, .datareleasewebinar {
  .video {
    height: 500px;
  }

  div.blueBorder { 
    @media (min-width: $screen-md-min){ 
      border-right: 5px solid $SecondaryBlueLight; 
    } 
    @media (max-width: $screen-sm-max) { 
      border-bottom: 5px solid $SecondaryBlueLight; 
    } 
  }

  div.last-viewed ol { 
    padding-left: 15px; 
  } 
   
  .video-category-item {
    padding: 5px;
    .video-thumbnail { 
      text-align: center;
      max-height: 195px;
    } 
    .video-title {
      max-height: 2em;
      line-height: 1em;
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
      &:before {
        content: "\02026";
        position: absolute;
        right: 0;
        bottom: 0;
      }
      &:after {
        content: "";
        position: absolute;
        right:0;
        width: 1em;
        height: 1em;
        background-color: white;
      }
    }
    .video-description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    } 
    img { 
      display: inline-block; 
      height: 195px; 
    } 
    &:hover { 
      background-color: $AnotherLightGrey; 
      border-radius: 4px; 
      transition: background-color .3s ease;
      .video-title:after {
        transition: background-color .3s ease;
        background-color: $AnotherLightGrey;
      }
    }
  }

  .video-category { 
    .glyphicon.glyphicon-chevron-left { 
      position: absolute; 
      top: 15%; 
      left: -30px; 
      padding: 120px 10px; 
      border-radius: 2px;
      font-size: 24px;
      &:hover { 
        background-color: $SecondaryBlueLight; 
        transition: background-color .3s ease; 
      } 
    } 
    .glyphicon.glyphicon-chevron-right { 
      position: absolute; 
      top: 15%; 
      right: -30px; 
      padding: 120px 10px; 
      border-radius: 2px;
      font-size: 24px; 
      &:hover { 
        background-color: $SecondaryBlueLight; 
        transition: background-color .3s ease; 
      } 
    } 
  } 
  
  vg-scrub-bar-current-time {
    background-color: $SecondaryBlueLight !important;
  }

  vg-time-display {
    width: 100px;
    font-size: 12px !important;
  }
}