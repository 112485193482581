@import '../_variables.scss';

.comp-builder {
  width: 100%;
  min-height: 0px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;

  .top-selections {
    flex: 0 1 auto;

    &:not(:last-child){
      padding-right: 25px;
      margin-top: 8px;
    }

    &:last-child{
      flex: 1 1;

      label {
        padding-right: 15px;
        margin-top: 8px;
      }
    }
  }
}
.aggregate-summary {
  width: 100%;
  margin: 0;
  padding: 0;

  .main-summary-content {
    h1 {
      span.icon {
        position: relative;
        top: 5px;
        right: 10px;
      }
    }

    .group-outline {
      border: 1px solid $SecondaryGrayLight;
      background-color: $SecondaryWhite;
      padding: 20px 30px 30px 30px;
      margin-top: 15px;
    }

    .vertical-align-middle {
      vertical-align: middle;
    }
  }

  .step-navigation {
    display: flex;
    justify-content: center;
  }
}

#gridCompBuilder{
  td {
    padding: 0em 0.3em;
    border-left-color: black !important;
    line-height: 1.3em !important;

    &.nowrap {
      white-space: nowrap;
    }

    &.paddingLeft0 {
      padding-left: 0px;
    }

    &.paddingRight0 {
      padding-right: 0px;
    }
  }

  .k-state-hover {
    background-color: #ebebeb !important;
    cursor: default !important;
  }

  .manageDelete {
    background-image: url("../../assets/portfolio/delete.png");
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-top: 3px;
  }

  .manageDelete:hover {
    background-image: url("../../assets/portfolio/delete-hover.png");
  }

  .noLeftBorder {
    border-left-width: 0px;
  }

  .noBottomBorder {
    border-bottom-width: 0px;
  }

  .noBorderRadius {
    border-radius: 0 0 0 0;
  }

  .ow-break-word {
    word-break: break-word; 
    word-wrap: break-word;
    max-width: 250px;
  }

  .verticalAlignMiddle {
    vertical-align: middle;   
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}


