@import '../_variables.scss';

property-list-half {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  //For Firefox//
  min-height: 0px;

  .property-search-table-half {
    overflow-y: auto;
    overflow-x: hidden;
    width: 397px;
    padding: 0 40px;
    margin-left: -30px;
    height: 100%;

    li {
      list-style-type: none;
    }

    hr {
      position: relative;
      right: 0;
      margin-left: -20px;
      width: 377px;
      z-index: -1;
    }
  }

  .property-details-scrolling-list {
    -ms-flex: 1 1;
    flex: 1 1;

    &.disabled::before {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba($PrimaryWhite, .6);
      z-index: 20;
    }
  }

  #exp-col-but-fix {
    font-weight: 200;
  }

  .exp-col-all {
    background-color: $LocalFiltersBackground;
    color: darken($SecondaryGrayLight, 20);
    width: 367px;
    height: 32px;
    border-top: 1px solid $LocalFiltersBorder;
    border-bottom: 1px solid $LocalFiltersBorder;

    button {
      font-weight: bold;
      background-color: $LocalFiltersBackground;
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .property-list-search {
    $searchHeight: 24px;

    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 8px;
    display: flex;

    input {
      height: $searchHeight;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    button {
      height: $searchHeight;
      line-height: $searchHeight/2;
      padding-left: 5px;
      padding-right: 5px;
    }

    button > i {
      top: -1px;
    }
  }

  .no-matches {
    background: rgba(255, 0, 0, .2);
  }
}