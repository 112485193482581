//
// Site.scss 
//
// SECTIONS
// #Content
// #Topbar
// #Sidebar
// #Footer
// #Expiration Message
// #Input Formatting
// #Links
// #Tooltip
// #Label
// #Colors
// #Cursors
// #Text
// #Buttons
// #Loading Spinner

@import '_variables.scss';

html,
body,
.appBody,
.nav-and-content,
.main-content {
  height: 100%;
}

.appBody {
  min-height: 100%;
  transition: background-color 0.5s ease;
  &.navbar-padding {
    padding-top: 70px;
  }
  &.home-page {
    height: auto;
    background-color: $HomePageBG;
  }
  &.login-page {
    height: auto;
    background-color: $PrimaryBlueDark;
  }
}

hr {
  border-color: $SecondaryGrayLight;
}

//********************************************\
// #Content
//********************************************/
.nav-and-content {
  position: relative; //give it a position so that we can use abolute positioning within this.
  transition: margin-left .3s;
  .main-content {
    height: auto;
    min-height: 100%;
    padding: 45px 45px;
  }
  .content-centered {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .footer-padding {
    padding-bottom: 270px;
    padding-top: 0px;
  }
}

.page-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px; //Half of the height of the loading graphic
  margin-left: -50px; //Half of the width of the loading graphic
  z-index: 10;
}

.main-content {
  max-width: 1250px;
  transition: opacity .5s;
  &.ng-enter {
    opacity: 0;
  }
  &.ng-enter-active {
    opacity: 1;
  }
  &.fade-out {
    opacity: 0;
    pointer-events: none;
  }
  &.expanded-content-width {
    max-width: 1440px;
  }
}

.modal {
  .content-centered {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.display-block {
  display: block;
}

.site-message-navexpanded {
  padding-left: $LeftNavWidth;
}


//********************************************\
// #Topbar
//********************************************/
.navbar-fixed-top.navbar-default {
  border: 0;
  font-size: 14px;
  background-color: $UtilityNavBackground;
  box-shadow: 0 0 12px -5px;
  ul {
    li {
      &.open > a,
      &.open > a:focus {
        background-color: transparent;
        border-color: transparent;
      }
      > a,
      &.vertical-divider {
        color: $UtilityNav;
        padding-top: 25px;
        padding-bottom: 25px;
        font-weight: 600;
        transition: background-color 150ms;
      }
      &.vertical-divider {
        padding-left: 10px;
        padding-right: 10px;
      }
      > a,
      &.expirationMessage {
        color: $UtilityNav;
        padding-top: 25px;
        padding-bottom: 25px;
        font-weight: 600;
      }
      > span,
      &.expiresOn {
        color: $SecondaryBlueLight;
      }
      &.contact,
      &.dropdown {
        a {
          .glyphicon {
            display: none;
          }
        }
      }
      .caret {
        margin: 0 15px 3px 10px;
      }
      a:hover,
      a:hover,
      &.open > a:hover {
        color: $PrimaryWhite;
        background: $UtilityNavHover;
      }
      a:active,
      a:active {
        background: $UtilityNavHover;
      }
      .dropdown-menu {
        background: $PrimaryGrayDark;
        a {
          color: $PrimaryWhite;
          padding: 10px 20px;
          font-weight: 400;
          border-bottom: rgba(255, 255, 255, 0.2) 1.5px solid;
          &.last {
            border-bottom: 0;
            padding-bottom: 5px;
          }
          &:not(.nohover):hover {
            background: #b1b1b1;
          }
        }
      }
    }
  }
  .navbar-header {
    a.home {
      &:hover {
        background: transparent;
      }
    }
    img {
      height: 70px;
    }
    @media (max-width: 767px) {
      margin-left: 30px;
    }
  }
  @media (max-width: 767px) {
    ul {
      float: right;
      margin: 0;
      li {
        float: left;
        &.glossary{
          display: none;
        }
        &.support {
          display: none;
        }
        &.dropdown {
          a.dropdown-toggle {
            padding-right: 0;
          }
          .dropdown-menu {
            position: absolute;
            > li {
              float: none;
              > a {
                color: $PrimaryWhite;
                &:hover {
                  background: #b1b1b1;
                  color: $PrimaryWhite;
                }
              }
            }
          }
        }
      }
    }
    .navbar-header {
      float: left;
    }
  }
  @media (max-width: 600px) {
    ul li.vertical-divider {
      padding: 25px 0;
    }
  }
  @media (max-width: 535px) {
    ul li {
      &.contact,
      &.dropdown {
        a {
          span.text {
            display: none;
          }
          .glyphicon {
            display: inline-block;
          }
        }
      }
      .dropdown-menu {
        left: -100px;
      }
    }
  }
}

//********************************************\
// #Sidebar
//********************************************/
.left-nav-expand {
  @media (min-width: 768px) {
    margin-left: $LeftNavWidth;
  }
}

.toggle-left-nav {
  cursor: pointer;
  position: fixed;
  z-index: 10000;
  &:hover {
    background: $LeftNavHover;
    color: $LeftNavText;
  }
  @media (max-width: 767px) {
    color: $LeftNav;
    background-color: transparent;
    top: 13px;
    left: 7px;
    font-size: 20px;
    height: 40px;
    width: 45px;
    padding-left: 11px;
    padding-top: 10px;
    border-radius: 5px;
    &.glyphicon:before {
      content: "\e236";
    }
  }
  @media (min-width: 768px) {
    color: $LeftNavText;
    background-color: $LeftNav;
    top: 71px;
    font-size: 9px;
    left: auto;
    margin-left: auto;
    height: 47px;
    width: 20px;
    padding-left: 5px;
    padding-top: 17px;
  }
}


/* Hide for mobile, show later */

.sidebar {
  position: fixed;
  top: 70px;
  width: 100%;
  background-color: $LeftNav;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
  z-index: 1000;
  transition: left .3s, visibility 0s;
  &.hiddenNav {
    left: -$LeftNavWidth;
    visibility: hidden;
  }
  a {
    color: $LeftNavText;
    font-size: $LeftNavTextSize;
  }
  /* Sidebar navigation */
  .nav-sidebar {
    li {
      border-bottom: 1px solid $LeftNavBorder;
      a {
        padding: 30px 40px;
      }
      :hover {
        background-color: $LeftNavHover !important;
        /* is there a better way to override bootstrap's defaults?*/
        color: $LeftNavText;
      }
      &.active {
        > a,
        > a:hover,
        > a:focus {
          color: $LeftNavText;
          background-color: $LeftNavSelected;
        }
      }
      :not(.active) {
        background-color: $LeftNav;
      }
    }
    @media (max-width: 767px) {
      li {
        display: inline-block;
        width: 49.5%;
        a{
          padding: 25px 30px;
          border-right: 1px solid $LeftNavBorder;
        }
      }
    }
  }
  @media (min-width: 768px) {
    bottom: 0;
    left: 0;
    width: $LeftNavWidth;
    display: block;
    padding-top: 0;
    border-right: 1px solid #eee;
  }
  @media (max-height: 300px) {
    bottom: 0;
  }
}

//********************************************\
// #Footer
//********************************************/
.footer {
  background: $Footer;
  color: $FooterBody;
  width: 100%;
  min-height: 225px;
  margin-top: -225px;
  position: relative;
  font-size: 14px;
  padding: 40px 60px;
  div {
    display: inline-block;
  }
  a {
    color: $SocialLink;
    &:hover {
      color: $SocialLinkHover;
    }
    &:active {
      color: $SocialLinkHover;
    }
  }
  .connect {
    font-size: 16px;
    display: block;
    .heading {
      font-weight: 800;
    }
    .social {
      display: inline-block;
      margin-left: 30px;
      img {
        margin-right: 5px;
        vertical-align: baseline;
      }
    }
  }
  .copy {
    margin: 20px 0;
  }
  .bottom-row {
    display: block;
    .site-map {
      margin-right: 20px;
    }
    .vertical-divider {
      margin: 0 10px;
    }
  }
}

//********************************************\
// #Expiration Message
//********************************************/
#exp-msg-breakpoint {
  display: none;
}

@media (max-width: 767px) {
  .expirationMessage {
    display: none !important;
  }
}

@media (max-width: 940px) {
  .expirationMessage {
    font-size: .8em;
    padding-top: 27px !important;
  }
  .glyphicon {
    margin-top: -1px;
  }
}

@media (max-width: 873px) {
  #exp-msg-breakpoint {
    display: block;
  }
  .expirationMessage {
    padding-top: 20px !important;
    text-align: center;
  }
}

//********************************************\
// #Input Formatting
//********************************************/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=text]::-ms-clear {
  width : 0;
  height: 0;
}

textarea {
  resize: none;
}

.disabled-input-container {
  display: inline-block;
  position: relative;
  & > input[disabled=disabled] + .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
  }
  .disabled-checkbox-popover{
    width: 250px;
  }
}


//********************************************\
// #Links
//********************************************/
a.normal-link {
  color: $SecondaryBlueLight !important;
}

a.red-link{
  color: red !important;
}
a.green-link{
  color: $ProtectionThresholdMet !important;
}

a.backToTop {
  border-style: solid;
  border-width: 1px;
  border-color: $DarkerGrey;
  border-radius: 2px;
  background: none;
  margin: 0;
  visibility: visible;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  z-index: 100;
  text-decoration: none;
  color: $PrimaryBlueDark;
  background-color: $AnotherLightGrey;
  opacity: .4;
  &:hover {
    opacity: 1;
    transition: opacity .2s 0s;
  }
  & > i {
    font-size: 45px;
    padding-left: 3px;
  }
}

a.noDecoration {
  text-decoration: none;
}

//********************************************\
// #Label
//********************************************/

label.normalWeight {
  font-weight: normal;
}

label.normalBottomMargin {
  margin-bottom: 0px;
}

//********************************************\
// #Colors
//********************************************/
.primary-blue-dark {
  color: $PrimaryBlueDark;
}

.secondary-blue-light {
  color: $SecondaryBlueLight;
}

.dark-darker-grey {
  color: $DarkDarkerGrey;
}

.bright-red {
  color: $BrightRed;
}

.green{
    color: $ProtectionThresholdMet;
}

.light-grey{
  color: $SecondaryGrayLight;
}

//********************************************\
// #Tooltip
//********************************************/
.tooltip-inner {
  background-color: $PrimaryWhite;
  color: black;
  border: solid 1px $SecondaryGrayLight;
  border-radius: 0px;
}

.tooltip-arrow {
  $directions: top, bottom, right, left;
  @each $d in $directions {
    .tooltip.#{$d} {
      border-#{$d}-color: $SecondaryGrayLight;
    }
  }
}

//********************************************\
// #Cursors
//********************************************/
.cursor-default {
  cursor: default;
}

.pointer-cursor {
  cursor: pointer;
}

.cursor-grabbable {
  cursor: move !important; /* fallback if grab cursor is unsupported */
  cursor: grab !important;

  &.active {
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
  }
}

.pointer-context-menu {
  cursor: context-menu;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

.nav:not(.nav-tabs),
.pagination,
.carousel,
.panel-title,
.trends-filters a {
  cursor: pointer;
}

//********************************************\
// #Text
//********************************************/
* {
  font-family: $FontFamily;
}

.glyphicon {
  font-family: 'Glyphicons Halflings' !important;
}

.bold {
  font-weight: bold;
}

.body-text {
  font-size: 15px;
  color: $Black;
}

.site-message {
  font-size: 14px;
  color: $BrightRed;
  font-weight: 600;
  margin-top: 15px;
  display:block;
  max-width:100%;
  word-wrap:break-word;
}

.appendTab {
  margin-left: $TabToSpaces;
}

.indent {
  padding-left: 2em;
}

.italic {
  font-style: italic;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.space-nowrap {
  white-space: nowrap;
}

.word-wrap {
  word-wrap:break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .primary-blue-dark;
}

h4.graph-title {
  color: $DarkDarkerGrey;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.word-break-150-width{
  word-break: break-all;
  min-width: 150px;
}

//********************************************\
// #Buttons
//********************************************/
.btn {
  img:first-child {
    padding-right: 6px
  }
  & > .glyphicon-triangle-left {
    padding-right: 3px;
    vertical-align: text-top;
  }
}

.btn-primary {
  color: $PrimaryWhite;
  background-color: $LeftNav;
  border-color: $LeftNav;
}

//********************************************\
// #Loading Spinner
//********************************************/

.loader {
  border: 14px solid #b2e6f3;
  border-top: 14px solid $SecondaryBlueLight; /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  margin-left: -45px;
  animation: spin 1.5s linear infinite;
  z-index: 1100;
  &.ng-animate { 
    transition: 0s none;
    -webkit-transition: 0s none;
    animation: 0s none;
    -webkit-animation: 0s none;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

//********************************************\
// #PIR SIR CustomCohort Padding Overrides
//********************************************/
.customKendo {
  &.demographics {
    padding: 0.3em 0.3em;
  }
  &.propertyInventory {
    padding: 0.3em 0.6em;
  }
}
