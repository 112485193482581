@import '../_variables.scss';

.advanced-search-menu {
  top: 105px;
  left: 367px;
  width: 1025px;
  height: 300px;
  position: absolute;
  border-left: $LocalFiltersBorder solid 1px;
  z-index: $MapZoomButtonsZIndex;
  background-color: white;

  .advanced-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }

  label {
    font-weight: 200;
    line-height: 1.5;
  }

  .adv-title {
    color: $PrimaryBlueDark;
    font-weight: bold;
  }

  .type-and-segment,
  .adv-misc,
  .adv-transaction {
    flex: 0 0 auto;
  }

  .horizontal-grouping {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 410px;

    input,
    select,
    .k-dropdown {
      flex: 0 0 auto;
      width: calc(50% - 5px);
    }
  }

  .adv-search-button {
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .adv-misc {
    .fixed-width:not(.error-message) {
      margin-top: 10px;
      border-radius: 0;
    }

    .fixed-height {
      max-height: 2em;
    }

    .all-ages-drop {
      width: calc(50% - 7px);
    }

    input.invalid {
     border-color: red !important;
    }
  }

  // .adv-reporting-period-type {
  //   color: $PrimaryBlueDark;
  //   font-weight: bold;
  //   margin-top: 5px;
  // }

  // .adv-iq-selection-msg {
  //   align-self: flex-end;
  //   position: absolute;
  //   bottom: -25px;
  //   left: 150px;
  // }

  .adv-transaction {
    .input-group:first-of-type,
    .adv-minmax > input[type="text"]:first-of-type,
    .sale-date-minmax > input[type="text"]:first-of-type {
      margin-right: 10px;
    }

    .adv-minmax,
    .sale-date-minmax {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      span {
        min-width: 70px;
      }

      input {
        max-width: 153px !important;
        min-width: 153px !important;
        border-radius: 0;
        padding-left: 5px;
      }
    }

    .sale-date-minmax {
      input {
        width: 100px !important;
        border-radius: 0;
      }

      span {
        left: -164px !important;
      }

      .sd-max {
        position: absolute;
        margin-left: 280px;
      }

      .sd-min {
        z-index: 30000;
        position: absolute;
        margin-left: 280px;
      }
    }
  }
}

