@import '../_variables.scss';

.share-aggregate {
  width: 100%;
  min-height: 0px;
  display: flex;
  flex-direction: row;

  .top-row {
    flex: 0 1 auto;
    padding-bottom: 5px;

    &:not(:last-child) {
      padding-right: 25px;
      margin-top: 8px;
    }

    &:last-child {
      flex: 1 1;

      label {
        padding-right: 15px;
        margin-top: 8px;
      }
    }
  }
}

#activeUsersGrid {
  .k-filter-row {
    th {
      text-align: center;
    }
  }

  td {
    padding: 0em 0.3em;
    line-height: 2em !important;
  }

  .verticalAlignMiddle {
    vertical-align: middle;   
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}

