@import '_variables.scss';

.panel-group {
  & .popover {
    z-index: 30;
  }
}

.report-item {
  & .report-name {
    margin-top: 0px;
    height: 52px;
  }
  & .report-purpose {
    height: 30px;

    &.additional-height {
      height: 45px;
    }
  }
  &.disabled::before {
    content:"";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($PrimaryWhite, .6);
    z-index: 20;
  }
  & .glyphicon-info-sign.link-color {
    color: #337ab7;
  }

  .download-link {
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
    &.disabled::before {
      content:"";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba($PrimaryWhite, .6);
      z-index: 20;
    }
  }
}

.report-messages {
  font-size: 18px;
  background-color: white;
  color: $BrightRed;
  font-weight: 600;
}

.report-error {
  top: 21px;
}
