@import '../_variables.scss';

.add-aggregate-main-container {
  width: 100%;

  .main-content-view {
    min-height: 30em;
    padding-bottom: 30px;

    h1 {
      span.icon {
        position: relative;
        top: 5px;
        margin-right: 10px;
      }
    }
  }

  .step-progress-style {
    .step-navigation {
      display: flex;
      justify-content: center;

      button {
        margin: 5px;
      }
    }
  }

  .group-outline {
    border: 1px solid $SecondaryGrayLight;
    padding: 5px;
    background-color: $SecondaryWhite;
  }

  .step-first-line {
    margin-top: 30px;
  }

  .step-second-line {
    margin-top: 15px;
  }

  .step-main-container {
    margin-top: 30px;
  }
}


.multi-steps > li.is-active:before, .multi-steps > li.is-active ~ li:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  .multi-steps > li.is-active:after, .multi-steps > li.is-active ~ li:after {
    background-color: #ededed;
  }
  
  .multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #000;
  }
  .multi-steps > li:before {
    content: '\2713'; //Check Mark Unicode Character
    display: block;
    margin: 0 auto 4px;
    color: #fff;
    background-color: rgb(0,48,86);
    border-color: rgb(0,48,86);
    width: 36px;
    height: 36px;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
  }
  .multi-steps > li:after {
    content: '';
    height: 10px;
    width: 100%;
    background-color: rgb(0,48,86);
    position: absolute;
    top: 14px;
    left: 50%;
    z-index: -1;
  }
  .multi-steps > li:last-child:after {
    display: none;
  }
  .multi-steps > li.is-active:before {
    background-color: rgb(0,48,86);
    color: #fff;
    border-color: rgb(0,48,86);
  }
  .multi-steps > li.is-active ~ li {
    color: #808080;
  }
  .multi-steps > li.is-active ~ li:before {
    background-color: #ededed;
    border-color: #ededed;
  }



.new-aggregate-name-step {
  .center-vertical {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.new-aggregate-location-step {
  .drop-zone {
    border: 4px dashed $SecondaryGrayLight;
    padding: 20px 5px 5px 5px;
    text-align: center;

    .drop-zone-topText {
      margin-Top: -15px;
      text-align: left;
    }

    .drop-zone-bottomText {
      color: $SecondaryGrayLight;
    }
  }

  .side-by-side-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
  }

  .left-group {
    padding: 15px;
    flex: 1 1 0px;
    min-width: 350px;
    margin: 10px;

    .address-container {
      display: flex;
      flex-wrap: wrap;

      .address-label {
        margin-top: 10px;
        margin-right: 5px;
        width: 100px;
        flex: 0 0 auto;
      }

      .address-input {
        min-width: 305px;
        flex: 1 1 305px;
        margin-right: 10px;
        margin-top: 5px;
      }

      .address-add-button {
        flex: 0 0 auto;
        margin-top: 5px;
      }
    }
  }

  .right-group {
    flex: 1 1 0px;
    min-width: 350px;
    margin: 10px;
  }
}

.new-aggregate-distance-step {
  .side-by-side-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .group {
      padding: 15px 20px;
      width: 380px;
      flex: 0 1 auto;
      margin: 5px;

      label {
        font-weight: normal;
      }

      .dropdown {
        width: 145px;
      }
    }
  }

  .custom-radius {
    height: 30px;
    vertical-align: bottom;
    padding-left: 12px;
    padding-right: 12px;
    width: 167px;
  }
}

.new-aggregate-share-step {
  .step-main-container {
    padding: 25px 40px;
  }

  .top-row-container {
    width: 100%;
    min-height: 0px;
    display: flex;
    flex-direction: row;

    .top-row {
      flex: 0 1 auto;
      padding-bottom: 5px;

      &:not(:last-child) {
        padding-right: 25px;
        margin-top: 8px;
      }

      &:last-child {
        flex: 1 1;

        label {
          padding-right: 15px;
          margin-top: 8px;
        }
      }
    }
  }

  #activeUsersGrid {
    .k-filter-row {
      th {
        text-align: center;
      }
    }

    td {
      padding: 0em 0.3em;
      line-height: 2em !important;
    }

    .vertical-align-middle {
      vertical-align: middle;
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }
  }
}