comp-builder-aggregate-search-criteria {
  span {
    font-weight: bold;
  }
  label {
    font-weight: 200;
  }
  .dropdown {
    max-width: 35%;
  }
  .trade-area {
    .info-trigger {
      display: inline-block;
    }
    label {
      min-width: 65px;
    }
  }
}