@import '../_variables.scss';

property-list-half-item > li,
li.property-list-half-item {
  width: 120%;
  padding: 0px 10px 0px 35px;
  border-bottom: 1px solid $SecondaryGrayLight;
  list-style-type: none;

  > :not(#flxbx-address) {
    line-height: 1.6;
  }

  .expanded-details {
    margin-bottom: 10px;
  }

  #prop-title {
    line-height: 0;
    padding-bottom: 10px;

    #chckbx-spacing {
      display: block;
      padding-left: 7px;
      white-space: pre-wrap;
    }

    .more-than-nine {
      padding-left: 0 !important;
    }
  }

  > :not(#prop-title) {
    margin-left: 40px;
  }

  #flxbx-address {
    flex: 1 0;
    display: inline-block;
    margin: -5px 0 7px 0;
    line-height: 1.4 !important;
  }

  .highlightedText {
    background: #dfdfdf;
  }

  .selectedHighlightedText {
    background: lighten($SecondaryBlueLight, 50%);
  }

  .walkScoreText {
    a {
      color: lighten($PrimaryBlueDark, 30);
    }
  }

  .btn {
    height: 100%;
    padding: 3px 6px;
  }
  .btn > img {
    height: 20px;
  }

  .btn > span {
    vertical-align: middle;
  }

  .top-group {
    display: flex;
  }
}
// This is a super long class name because the popover in propertyListHalfItem falls outside of the namespacing class.
// It should not be used elsewhere.
// This is due to using popover-append-to-body, which places the popover at the end of the html file.
// Used because without, the popover falls overtop of the button itself and just ends up flashing due to the mousenter trigger
.property-list-half-item-par-button-popover {
  max-width: 350px;
}
