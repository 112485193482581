@import '../_variables.scss';

trade-area {
  position: absolute;
  justify-content: space-between;
  border-left: $LocalFiltersBorder solid 1px;
  top: 105px;
  left: 367px;
  width: 1000px;
  height: 300px;
  padding: 20px 15px 15px 25px;
  z-index: $MapZoomButtonsZIndex;
  background-color: white;
  
  input.ng-invalid {
    border-color: red !important;
  }

  label {
    font-weight: 200;
    line-height: 1.5;
  }

  color-label.radius1 {
    background-color: rgba(0,51,102,0.6); 
  }
  color-label.radius2 {
    background: rgba(0,51,102,0.3);
  }
  
  .custom-polygon {
    color: darken($PrimaryGrayDark, 10);
    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }

  .radius-info {
    font-size: 85%;
    cursor: pointer;
  }

  .search-button {
    position: absolute;
    right: 48px;
    bottom: 21px;
  }

  .k-dropdown {
    width: 92%;
  }
}