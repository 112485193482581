@import '_variables.scss';

#login {
  .logo {
    display: inline-block;
    width: 54%;
    margin-right: -10px;
    margin-bottom: 40px;
    margin-top: 15px;
    img {
      max-width: 225px;
      max-height:53px;
      width: 100%;
    }

    @media (max-width: 690px) {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .tagline {
    width: 46%;
    display: inline-block;
    font-size: 20px;
    font-weight: lighter;
    color: $PrimaryWhite;
    vertical-align: middle;
     margin-bottom: 40px;

    @media (max-width: 690px) {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 60px;
    }
  }

  form {
    background: white;
    margin: -35px -60px;
    padding: 35px 60px;
    border-radius: 5px;
    min-width: 685px;
    label {
      color: #021458;
      font-size: 18px;
      font-weight: 500;
    }

    .actions {
      margin-top: 35px;
      min-height: 45px;
      .btn {
        border-radius: 0;
        padding: 5px 20px;
        background: $LoginActionBlue;
      }
      .checkbox {
        display: inline-block;
        top: 1px;
        margin-left: 15px;
        font-size: 15px;
        label {
          color: $LoginActionBlue;
        }
      }
      .submit-login {
        float: left;
        & > button {
          font-size: 16px;
        }
      }
      .links {
        float: right;
        line-height: 45px;
        text-align: right;

        a, .vertical-divider {
          display: inline-block;
          color: $LoginActionBlue;
          font-size: 16px;
        }
        .vertical-divider {
          margin: 0 15px;
        }
      }

      @media (max-width: 525px) {
        text-align: center;

        .submit-login {
          float: none;
        }
        .links {
          float: none;
          line-height: 1.1em;
          text-align: center;
          margin-top: 20px;
          .vertical-divider {
            display: block;
            opacity: 0;
          }
        }
      }
    }
  }
  
  #font-smaller {
    font-size: 16px;
  }
  .requirements{
    color: $PrimaryWhite;
    font-size: 15px;
    margin-top:60px;
    a {
      color: $PrimaryWhite;
      font-weight: bold;
    }
  }
}