@import '_variables.scss';
.subscription-css {
  .checkbox + .checkbox,
  .radio + .radio {
    margin-top: 10px;
  }
}

.history-view {
  margin-top: 40px;
}
.sub-history-buttons {
  margin-top: 30px;
}
.sub-users-buttons {
  position: absolute;
  top: 63px;
  right: 15px;
}
.sub-portfolios-buttons {
  position: absolute;
  top: 63px;
  right: 15px;
}

.sub-sir-label{
  label {
    padding-left: 0;
    padding-right: 5px;
  }
  input {
    width: 100px;
  }
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  .sub-sir-button {
    padding: 3px 5px 1px 5px;
    & > span {
      vertical-align: text-top;
    }
  }
}

.sub-buttons {
  padding: 5px 10px;
  margin-left: 5px;
  background-color: $PrimaryBlueDark;
  color: $PrimaryWhite;
  outline-width: 0px;
  border-style: none;
  :visited {
    color: $PrimaryWhite;
  }
}

.sub-buttons-disabled{
  padding: 5px 10px;
  margin-left: 5px;
  background-color: $PrimaryGrayDark !important;
  color: $PrimaryWhite !important;
  cursor: not-allowed !important;
  outline-width: 0px;
  border-style: none;
  :visited {
    color: $PrimaryWhite;
  }
}

.sb-container-flxbx {
  position: relative;
  width: 100%;
  float: right;
  right: 14px;
  display: flex;
  justify-content: flex-end;
  width: 400px;
  div {
    flex-grow: 1;
    margin-right: 5px;
    text-align: center;
  }
}

.chckbx-sub {
  label {
    padding-left: 0;
    color: $PrimaryGrayDark;
  }
  position: relative;
  left: 25px;
  width: 200px;
}

#include-exp-sub {
  border-radius: 0;
}
#new-sub-button {
  max-width: 150px;
}
#reset-button-sub {
  max-width: 100px;
}

.subscription-hr {
  border-color: darken(lightgray, 10);
}