@import '../_variables.scss';

.bing-map-edit-map {
  position: absolute;
  z-index: $MapOverlayZIndex;
  background-color: white;
  width: 250px;
  height: 40px;
  bottom: 0;
  text-align: left;
  margin-left: 2px;

  &.edit-option-open {
    height: 230px;
    border: solid 1px lightgray;
  }

  &.sidebar-open {
    left: $LocalSidebarHalfWidth + 3px;
  }

  .header-wrapper {
    display: flex;
    padding-left: 10px;
  }
  
  .edit-map-title {
    flex: 1 0 auto;
    line-height: 40px;
    color: $PrimaryBlueDark;
    font-weight: bold;
  }

  .edit-map-arrow {
    flex: 0 0 auto;
    align-self: center;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    transition: background-color 200ms;

    &:hover {
      background-color: darken($PrimaryWhite, 10);
    }
  }

  .edit-map-menu {
    color: $PrimaryBlueDark;
    line-height: 2;
    font-size: 1.1em;

    .remove-boundaries {
      &:hover {
        text-decoration: underline;
      }

      .glyphicon {
        margin-bottom: 3px;
        vertical-align: middle;
      }
    }
    
    .image-at-end {
      width: 40px;
      height: 40px;

      &.extra-spacing {
        margin-left: 10px;
      }
    }
  }
}
