@import '_variables.scss';

.datareleasewebinar {
    div.dataReleaseFiles{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 35em;
    }
    div.quarter{
        select{
            min-width: 50%;
        }
    }

    .disabled::before {
      content:"";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba($PrimaryWhite, .6);
      z-index: 20;
    }
}