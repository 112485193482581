@import '_variables.scss';

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.col-no-pad {
  @extend .paddingLeft0;
  @extend .paddingRight0;
}

.marginLeft0 {
  margin-left: 0px;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft25 {
  margin-left: 25px;
}

.marginLeft30 {
  margin-left: 30px;
}

.marginLeft35 {
  margin-left: 35px;
}

.marginLeft40 {
  margin-left: 40px;
}

.marginLeft45 {
  margin-left: 45px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginRight0 {
  margin-right: 0px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight15 {
  margin-right: 15px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginRight25 {
  margin-right: 25px;
}

.marginRight30 {
  margin-right: 30px;
}

.marginRight35 {
  margin-right: 35px;
}

.marginRight40 {
  margin-right: 40px;
}

.marginRight45 {
  margin-right: 45px;
}

.marginRight50 {
  margin-right: 50px;
}

.marginTop0 {
  margin-top: 0px;
}

.marginTop5 {
  margin-top: 5px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop25 {
  margin-top: 25px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop35 {
  margin-top: 35px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginTop45 {
  margin-top: 45px;
}

.marginTop50 {
  margin-top: 50px;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom25 {
  margin-bottom: 25px;
}

.marginBottom30 {
  margin-bottom: 30px;
}

.marginBottom35 {
  margin-bottom: 35px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.marginBottom45 {
  margin-bottom: 45px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.vPadding0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.vPadding5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.vPadding10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.vPadding15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.vPadding20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.vPadding25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.vPadding30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.vPadding35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.vPadding40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.vPadding45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.vPadding50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.paddingTop0 {
  padding-top: 0px;
}

.paddingTop5 {
  padding-top: 5px;
}

.paddingTop10 {
  padding-top: 10px;
}

.paddingTop15 {
  padding-top: 15px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingTop25 {
  padding-top: 25px;
}

.paddingTop30 {
  padding-top: 30px;
}

.paddingTop35 {
  padding-top: 35px;
}

.paddingTop40 {
  padding-top: 40px;
}

.paddingTop45 {
  padding-top: 45px;
}

.paddingTop50 {
  padding-top: 50px;
}

.paddingBottom0 {
  padding-bottom: 0px;
}

.paddingBottom5 {
  padding-bottom: 5px;
}

.paddingBottom10 {
  padding-bottom: 10px;
}

.paddingBottom15 {
  padding-bottom: 15px;
}

.paddingBottom20 {
  padding-bottom: 20px;
}

.paddingBottom25 {
  padding-bottom: 25px;
}

.paddingBottom30 {
  padding-bottom: 30px;
}

.paddingBottom35 {
  padding-bottom: 35px;
}

.paddingBottom40 {
  padding-bottom: 40px;
}

.paddingBottom45 {
  padding-bottom: 45px;
}

.paddingBottom50 {
  padding-bottom: 50px;
}

.paddingLeft0 {
  padding-left: 0px;
}

.paddingLeft5 {
  padding-left: 5px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.paddingLeft15 {
  padding-left: 15px;
}

.paddingLeft20 {
  padding-left: 20px;
}

.paddingLeft25 {
  padding-left: 25px;
}

.paddingLeft30 {
  padding-left: 30px;
}

.paddingLeft35 {
  padding-left: 35px;
}

.paddingLeft40 {
  padding-left: 40px;
}

.paddingLeft45 {
  padding-left: 45px;
}

.paddingLeft50 {
  padding-left: 50px;
}

.paddingRight0 {
  padding-right: 0px;
}

.paddingRight5 {
  padding-right: 5px;
}

.paddingRight10 {
  padding-right: 10px;
}

.paddingRight15 {
  padding-right: 15px;
}

.paddingRight20 {
  padding-right: 20px;
}

.paddingRight25 {
  padding-right: 25px;
}

.paddingRight30 {
  padding-right: 30px;
}

.paddingRight35 {
  padding-right: 35px;
}

.paddingRight40 {
  padding-right: 40px;
}

.paddingRight45 {
  padding-right: 45px;
}

.paddingRight50 {
  padding-right: 50px;
}

@media(max-width: $screen-xs-max) {
  .col-xs-no-pad {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media(min-width: $screen-sm-min) {
  .col-sm-no-pad {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media(min-width: $screen-md-min) {
  .col-md-no-pad {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media(min-width: $screen-lg-min) {
  .col-lg-no-pad {
    padding-left: 0px;
    padding-right: 0px;
  }
}


@media(max-width: $screen-xs-max) {
  .pull-right-xs {
    float: right;
  }
}

@media(min-width: $screen-sm-min) {
  .pull-right-sm {
    float: right;
  }
}

@media(min-width: $screen-md-min) {
  .pull-right-md {
    float: right;
  }
}

@media(min-width: $screen-lg-min) {
  .pull-right-lg {
    float: right;
  }
}
