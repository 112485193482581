@import '_variables.scss';

.clientApiPopup {
  .width80Percent {
    width: 80%;
  }

  .setTopToMinus1 {
    top: -1px;
  }

  .borderColor {
    border: 1px solid #ccc;
  }
}

#clientApiGrid {
  .disabled {
    color: gray;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
  }
}
