@import '../_variables.scss';

comp-builder-aggregate-subject-location-grid,.comp-builder-aggregate-subject-location-grid {
  .text-input-form {
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    
    span {
      min-width: 100px;
      margin: auto;
      font-weight: bold;
      flex: 0 1;
    }
    input {
      margin-left: 5%;
      border-radius: 0;
      flex: 1 0;
    }
  }

  // Set default button color
  .k-grid td a {
    color: $PrimaryBlueDark;
  }

  //Get rid of icon cutoff
  .k-icon {
    overflow: visible;
  }

  // Set grid command buttons to use only the icon.
  .k-grid .k-grid-toolbar .k-grid-add,
  .k-grid tbody .k-grid-edit,
  .k-grid tbody .k-grid-update,
  .k-grid tbody .k-grid-cancel,
  .k-grid tbody .k-grid-delete {
    min-width: 0;
  }

  // These are the overrides to replace the kendo default icons with their glyphicon equivalent to match our styles
  .k-grid .k-grid-toolbar .k-grid-add .k-icon,
  .k-grid tbody .k-grid-edit .k-icon,
  .k-grid tbody .k-grid-update .k-icon,
  .k-grid tbody .k-grid-cancel .k-icon,
  .k-grid tbody .k-grid-delete .k-icon {
    margin: 0;
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .k-grid tbody .k-grid-edit .k-icon:before {
    content: "\270f";
  }
  .k-grid tbody .k-grid-update .k-icon:before {
    content: "\e172";
  }
  .k-grid tbody .k-grid-cancel .k-icon:before {
    content: "\e088";
  }
  .k-grid tbody .k-grid-delete .k-icon:before {
    content: "\e082";
  }

  .k-primary {
    background-color: transparent;
    color: $PrimaryBlueDark !important;

    &:hover {
      background-color: transparent;
    }
  }
  
  // Hide the validation tooltips. We cover our own error messages
  .k-tooltip-validation.k-invalid-msg {
    display: none !important;
  }

  // Give fields that are invalid when editing the grid a red outline
  .k-invalid {
    border-color: $BrightRed;
  }

  // Styling for the edit button once it is disabled.
  .disabled {
    color: gray;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
  }

  .info-trigger {
    display: inline-block;
    line-height: 1rem;
    vertical-align: middle;
  }

  .drop-zone {
    border: 4px dashed $AnotherLightGrey;
    margin-top: -4%;
    padding: 4%;
    text-align: center;
    &.drag-over {
      border: 4px dashed $DarkerGrey;
    }
  }

  .stopFlashingOnAdd {
    white-space: nowrap;
    text-overflow: clip;
    max-width: 69px;
  }

  .stopButtonsFlashingOnAdd {
    overflow: no-display;
    text-overflow: clip;
  }

  .k-grid-edit-row {
    .stopButtonsFlashingOnAdd {
      visibility: visible !important;
      display: inline-block !important;
    }
  }

  .aboveTable{
    width: 100%; 
    display:inline-flex;
  }
  .width50{
    width: 50%;
  }

  .redMessage{
    width: 100%; 
    text-align: right;
    color: $BrightRed;
  }
  
}
