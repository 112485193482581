@import '_variables.scss';
@import '_components.scss';

/* Applies to all Panels */
.panel {
  margin-bottom: 40px;
  border-radius: 5px;

  .panel-heading {
    border-color: rgba(255, 255, 255, 0.25);
    font-size: 18px;
    padding: 15px 30px;
  }

  .panel-body {
    padding: 30px 15px;
    /* override kendo-ui/form elements in panel*/
    .k-block, .k-button, .k-textbox, .k-drag-clue, .k-touch-scrollbar, .k-window, .k-window-titleless .k-window-content, .k-window-action, .k-inline-block, .k-grid .k-filter-options, .k-grouping-header .k-group-indicator, .k-autocomplete, .k-multiselect, .k-combobox, .k-dropdown, .k-dropdown-wrap, .k-datepicker, .k-timepicker, .k-colorpicker, .k-datetimepicker, .k-notification, .k-numerictextbox, .k-picker-wrap, .k-numeric-wrap, .k-colorpicker, .k-list-container, .k-calendar-container, .k-calendar td, .k-calendar .k-link, .k-treeview .k-in, .k-editor-inline, .k-tooltip, .k-tile, .k-slider-track, .k-slider-selection, .k-upload, .k-split-button,
    input.form-control {
      border-radius: 0;
    }

    .form-btn, .form-btn:hover, .k-button {
      @include transparent-button;
    }
  }
}

/*slide carousel stuff*/
.carousel {
  .carousel-inner {
    > .item {
      padding: 0 30px;
      @media(min-width: 600px) {
        padding: 0 60px;
      }
      @media(min-width: 700px) {
        padding: 0 90px;
      }
    }
  }
  .carousel-control {
    &.left, &.right {
      background-image: none;
      width: 30px;
      @media(min-width: 600px) {
        width: 60px;
      }
      @media(min-width: 700px) {
        width: 90px;
      }

      span.glyphicon {
        &.glyphicon-chevron-left, &.glyphicon-chevron-right {
          font-size: 18px;
          height: inherit;
          margin: 0;
          margin-top: -9px;
          width: inherit;
          left: inherit;
          right: inherit;
        }
      }
    }
  }
  .carousel-indicators {
    bottom: -44px;
    li {
      background-color: $SlideBubble;
      width: 8px;
      height: 8px;
      margin: 3px;
      &.active {
        background-color: $ActiveSlideBubble;
        width: 8px;
        height: 8px;
      }
    }
  }

  .carousel-control, .carousel-control:focus, .carousel-control:hover {
    color: $SlidesControls;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    opacity: 1;
  }

  .carousel-control:focus, .carousel-control:hover {
    color: $SlidesControls;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    opacity: .5;
  }
}

.glyphicon-chevron-left:before {
  content: "\e257"
}

.glyphicon-chevron-right:before {
  content: "\e258"
}

.webinar-slide {
  margin-top: 40px;
  @media (min-width: 1100px) {
    margin-top: 0;
    margin-left: $WebinarWidth + 45px; //45 because there's a left margin and margin between the left item and this item
  }

  .panel-heading {
    color: $DashboardPanelHeaderTextColor;
    border-color: $DashboardPanelHeaderBorderColor;
  }
  .panel-body {
    height: 372px;
    padding: 25px 0 30px;

    .slide-image {
      max-width: $SlideImageWidth;
      max-height: $SlideImageHeight;
      margin: auto;
      border: 1px solid $WebinarBorder;
      height: 100%;
      width: 100%;
    }

    @media (max-width: 550px){
      height: auto;
      .carousel {
        margin-bottom: 30px;
        .carousel-indicators {
          bottom: -55px;
        }
      }
    }
  }
}

.webinar-info {
  //width and height not 'needed', but without this if they added an image that's not the correct size it will ruin the layout
  max-width: 100%;
  width: $WebinarWidth;
  @media (min-width: 1100px) {
    float: left;
  }
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto 20px; /*when the line wraps there is no gap at the bottom*/

  img {
    max-width: 100%;
  }
}

/*new carousel*/
.news-carousel {
  @media (min-width: 1250px) {
    width: 100%;
  }
  .panel-heading {
    color: $DashboardPanelHeaderTextColor;
    border-color: $DashboardPanelHeaderBorderColor;
  }
  .panel-body {
    font-size: 14px;
    text-align: left !important;
    padding: 22px 0;

    .carousel {
      .carousel-inner {
        > .item {
          padding: 0 20px;
          @media(min-width: 600px) {
            padding: 0 30px;
          }
          @media(min-width: 700px) {
            padding: 0 50px;
          }
        }
      }
      .carousel-control {
        &.left, &.right {
          background-image: none;
          width: 20px;
          @media(min-width: 600px) {
            width: 30px;
          }
          @media(min-width: 700px) {
            width: 50px;
          }
        }
      }
      .carousel-indicators {
        width: inherit;
        position: relative;
        left: inherit;
        bottom: 0px;
        margin: 0 20px;
        @media(min-width: 600px) {
          margin: 0 30px;
        }
        @media (min-width: 750px) {
          text-align: left;
        }
        @media(min-width: 700px) {
          margin: 0 50px;
        }
      }

      .news-item{
        .news-image{
          display: inline-block;
          max-height: 235px;
          max-width: 100%;
          width: auto;
          @media (min-width: 750px) {
            float:right;
            margin-left: 30px;
          }
        }
        .news-title {
          font-weight: bold;
          text-align: left;
          color: $NewsTitleColor;
          max-height: 40px;
          overflow: hidden;
        }

        .news-text{
          max-height: 100px;
          margin-bottom: 20px;
          overflow: hidden;
        }

        .k-button {
          background-color: $NewsButtonColor;
        }
      }
    }
  }
}

.recent-activity.panel {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 330px;
  overflow: hidden;
  margin-top: 40px;
  @media (min-width: 1100px){
    width: 54.5%;
  }

  .panel-heading {
    color: $DashboardPanelHeaderTextColor;
    border-color: $DashboardPanelHeaderBorderColor;
  }
  .panel-body {
    font-size: $RecentActivityBodyTextSize;
    padding-top: 5px;
  }

  .activity {
    cursor: pointer;
  }
  .activity > a > img {
    display: inline;
    float: left;
    padding-top: 8px;
    padding-right: 10px;
  }
}

.search-widgets {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-left: 0;

  @media (min-width: 1100px){
    width: 45%;
    padding-left: 35px;
  }

  .savedLocalSearch {
    padding-left: 0px;
    padding-right: 0px;

    .panel {
      @media (min-width: 1100px){
        margin-bottom: 25px;
      }
      background-color: $SearchLocalBackground;
      border: $SearchLocalBorder;
      color: $SearchLocalText;
      margin-top: 40px;
    }
  }
  .quick-property-search {
    padding-left: 0px;
    padding-right: 0px;
    .panel {
      background-color: $QuickSearchBG;
      border: $QuickSearchBorder;
      color: $QuickSearchText;
      a {
        color: $QuickSearchText !important;
        text-align: center;
        display: inline-block;
        width: 100%;
        font-size: $LeftNavTextSize;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* special widths for when left nav is expanded */
.left-nav-expand {
  .carousel {
    .carousel-inner {
      > .item {
        padding: 0 20px;
        @media(min-width: 200px) {
          padding: 0 40px;
        }
        @media(min-width: 1400px) {
          padding: 0 90px;
        }
      }
    }
    .carousel-control {
      &.left, &.right {
        background-image: none;
        width: 20px;
        @media(min-width: 200px) {
          width: 40px;
        }
        @media(min-width: 1400px) {
          width: 90px;
        }
      }
    }
  }
  .webinar-info {
    float:none;
    @media (min-width: 1250px) {
      float: left;
    }
  }

  .webinar-slide {
    margin-left: 0;
    @media (min-width: 1250px) {
      margin-top: 0;
      margin-left: $WebinarWidth + 45px; //45 because there's a left margin and margin between the left item and this item
    }
  }

  .news-carousel {
    .panel-body {
      .carousel {
        .news-item {
          .news-image {
            float: none;
            margin-left: 0;
            @media (min-width: 1100px) and (max-width: 1200px), (min-width: 1350px) {
              float: right;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }

  .recent-activity.panel {
    @media (min-width: 1250px) {
      width: 54.5%;
    }
  }

  .search-widgets {
    @media (min-width: 1250px) {
      width: 45%;
      padding-left: 35px;
    }
  }
}

.ellipsis-text-overflow {
  overflow: hidden;
  height: 50px;
  line-height: 22px;
  &:before {
    content: "";
    float: left;
    width: 5px;
    height: 47px;
  }
  & > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px;
  }
  &:after {
    content: "\02026";
    box-sizing: content-box;
    /*-webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;*/
    float: right;
    position: relative;
    top: -25px;
    left: 100%;
    width: 20px;
    margin-left: -20px;
    padding-right: 5px;
    text-align: right;
    background-size: 100% 100%;
    /* 512x1 image,gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
    background-color: white;
  }
}
