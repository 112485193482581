@import '_variables.scss';

.sub-prod-btn {
  margin-top: 40px;
  border: none;
}

a.btn-primary:hover{
  background-color: $PrimaryBlueDark;
}

.gray-banner-subscriptions {
  width: 100%;
  height: $SubscriptionBannerHeight;
  position: absolute;
  right: 0px;
  background-color: #efefef;
  z-index: -1;
}

.italics {
  font-style: italic;
  color: darken(lightgray, 10);
}

.light-gray{
  color: darken(lightgray, 10);
}

.subscription-products-page {
  li {
    list-style-type: none;
    margin-left: -20px;
  }
  h4 {

  }
  .top-data label {
    margin-top: 30px;
    margin-bottom: 30px;
    color: black;
  }
  .access-header {
    margin-bottom: 20px;
  }
  .third-columns-products {
    margin-top: 50px;
  }
  .list-spacing{
    margin-bottom: 40px;
  }
  .selection-row-padding {
    padding-right: 15px;
  }
  .geography-section{
    font-weight: 700;
    font-size: 18px;
  }
}

.bootstrap-duallistbox-container{
  & .info-container{
    display: none;
  }
  & > .box1 > select {
    height: 190px !important;
  }
  & > .box2 > select {
    height: 190px !important;
  }
}

.product-group-name, .region-name {
  margin-left: -20px;
}

.national-header-row {
  label {
    font-weight: bold !important;
  }
}

.national-products-row {
  left: 30px !important;
}

.quality-metrics-header-row {
  label {
    font-weight: bold !important;
  }
  & .glyphicon-info-sign.link-color {
    color: #337ab7;
  }
  .popover-update {
    top: -85px !important;
    left: -74px !important;
    .popover-content {
      padding-right: 9px !important;
    }
  }
}

.quality-metrics-products-row {
  left: 30px !important;
}

.iqDataAccess-header-row {
  label {
    font-weight: bold !important;
  }
}

.iqDataAccess-products-row {
  left: 30px !important;
}

.chckbx-modify-sub-type, .chckbx-modify-sub-regions {
  label {
    padding-left: 0;
    font-weight: bold;
  }
  position: relative;
  left: 20px;
  width: 200px;
  margin-bottom: 0px !important;
}

.chckbx-modify-isRenewal {
  label {
    padding-left: 0;
    font-weight: bold;
  }
  position: relative;
  left: 15px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.chckbx-modify-sub-products-first {
  label {
    padding-left: 0;
  }
  position: relative;
  left: 40px;
  width: 200px;
}

.chckbx-modify-sub-products-other {
  label {
    padding-left: 0;
  }
  left: 10px !important;
}

.modify-access-popup {
  .modal-dialog {
    width: 1090px !important;
  }

  .modal-content {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
  }

  .modal-body {
    padding-left: 35px !important;
    padding-right: 35px !important;
    padding-bottom: 35px !important;
  }

  .top-row-labels {
    color: $PrimaryBlueDark;
  }

  .top-row-data {
    margin-top: 5px;

    .k-datepicker {
      width: 100% !important;
    }
  }

  .top-row-sub-popup {
    margin-top: 5px;

    .k-dropdown {
      width: 100% !important;
    }

    #subscriptionType {
      height: 30px !important;
    }

    #company {
      -webkit-border-radius: 0px !important;
      -moz-border-radius: 0px !important;
      border-radius: 0px !important;
    }

    .dropdown-menu {
      height: 150px !important;
      overflow-y: auto !important;
    }
  }

  .middle-header-row {
    margin-top: 35px;
  }

  .selection-row {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .selection-row-padding {
    padding-right: 10px;
  }

  .action-row {
    margin-top: 20px;
  }

  .action-row-buttons {
    float: right !important;
    padding-right: 15px;
  }

  .chckbx-modify-sub-cbsas-first {
    label {
      padding-left: 0;
    }

    position: relative;
    left: 40px;
    width: 100px;
  }

  .chckbx-modify-sub-cbsas-other {
    label {
      padding-left: 0;
    }

    left: 10px !important;
    padding-left: 10px !important;
  }
}

.clientapi-productgroup-row {
  label {
    font-weight: bold !important;
  }
}

.clientapi-products-row {
  left: 60px !important;
}

.clientapi-popup {
  display: inline;

  .glyphicon-info-sign.link-color {
    color: #337ab7;
  }

  .popover-update {
    top: -84px !important;
    left: -68px !important;

    .popover-content {
      padding-right: 9px !important;
    }
  }
}