@import '../_variables.scss';

property-list-full {
	position: absolute;
	z-index: $MapZoomButtonsZIndex;
	background-color: white;
  top: 105px;
  bottom: 0;
  width: 95%;
  min-height: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
	
  ::webkit-scrollbar {
    margin-left: -20px;
  }

  .select-properties-section {
    flex: 0 1;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    justify-content: flex-start;
    align-items: center;
  	border-bottom: solid 1px lighten(#AEBCBA, 20);
    border-top: solid 1px lighten(#AEBCBA, 20);
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
		min-height: 0px;

		.checkbox-list {
    	flex: 1 1;
    }

    .results {
    	flex: 1 1;
      font-style: italic;
      color: gray;
      text-align: right;
      margin-bottom: 5px;
    }
  }

  .property-grid {
  	flex: 0 1;
    min-height: calc(100% - 200px); //may need tweaking
  }

  .property-grid-checkbox {
    vertical-align: top;
    margin-right: 5px;
  }

  .export-properties-section {
    flex: 0 1;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    min-height: 0px;

    & > * {
      flex: 1 1;
    }

    p {
      padding-top: 7px;
    }
    .glyphicon {
      color: grey;
      font-size: x-small;
    }
  }  
}

