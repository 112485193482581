@import '_variables.scss';

.admin-tabs {
  .background-grey {
    width: 100%;
    height: 103px;
    border-bottom: solid 2px lighten($SecondaryGrayLight, 15);
    position: absolute;
    left:0;
    right: 0;
    background-color: $AnotherLightGrey;
    z-index: -1;
  }

  .tabs {
    position: relative;
    left: -60px;

    .bottom-align {
      margin-top: 65px;
      background-color: rgba(0,0,0,0);
    }
  }
}

.child-tabs {
  margin-top: 3%;

  .tabs {
    position: relative;
    left: -60px;
  }
}

.nav-tabs {
  > li {
    margin-left: 7px;
    a {
      font-size: 12pt;
    }
    &:first-child {
      margin-left:12px;
    }
    > a {
      color: white;
      background-color: $PrimaryBlueDark;
      padding: 7px 25px;
      margin-right: 0;
    }
    &.active {
      > a, > a:focus, > a:hover {
        color: $PrimaryBlueDark;
        cursor: default;
        background-color: $SecondaryWhite;
      }
    }
  }
  :hover {
    color: $PrimaryBlueDark !important;
    background-color: $SecondaryWhite !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
