@import '../_variables.scss';

.section-title {
  color: $PrimaryBlueDark;  
  font-size: 18px; 
  font-weight:normal;   
}

.propertyinforpt-map-section {
    position: relative; 
    width:100%;   
    padding-bottom: 30%;

    bing-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    a {
        img {
        position: absolute;  
        left: -10px;  
        height: 50px;
        width: 50px;
        }
      }
    }

    .bing-map-tou {
      position: absolute;
      bottom: -25px;
      right: 0;
    }
}

.propertyinforpt-map-header{
    img{
      height: 20px;
      width: 13px;
    }
}

#imgHospital-map-header{
  height: 20px;
  width: 22px;
}

.reset-filters{
  padding-bottom: 0px;
  button {
    color: $PrimaryWhite !important;
    background-color: $PrimaryBlueDark !important;
    border-radius: 0;
    padding: 5px 15px;
  }
}
.grid-narrow-rows{
    td{
        padding-top : 0;
        padding-bottom : 0;
    }
}

#gridDemographics{
  th{
    text-align:center;
  }
}

.hospital-pin-text div {
  color: black !important;
}

.black-text{
    color:black;
}

.propInfoExportButtons{
  .btn > img {
    height: 20px;
  }
}