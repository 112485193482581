@import '_variables.scss';
@import '_components.scss';

.trends-filters {
  .grayText {
    color: $PrimaryGrayDark;
    font-size: 15px;
    margin-right: 15px;
  }

  .nav-tabs {
    > li {
      margin-left: 7px;

      &:first-child {
        margin-left: 12px;
      }

      > a {
        color: white;
        background-color: $PrimaryBlueDark;
        padding: 7px 25px;
      }

      &.active {
        > a, > a:focus, > a:hover {
          color: $PrimaryBlueDark !important;
          cursor: default;
          background-color: $SecondaryWhite !important;
        }
      }
    }
  }

  .tab-content {
    background-color: $SecondaryWhite;
    border: 1px solid #ddd;
    border-top: 0;
    padding: 25px 35px;
    font-weight: bold;

    .filter-links {
      color: $PrimaryBlueDark;
      margin: 0 0 0 25px;
      font-size: 15px;
      font-weight: bold;

      &.first {
        margin-left: 30px;
      }
    }

    .display-data {
      float: right;
      margin-top: 22px;

      button {
        color: white;
        background-color: $PrimaryBlueDark;
        border-radius: 0;
        padding: 5px 15px;
      }
    }

    .filter-label {
      margin: 0 0 7px 15px;
      font-size: 15px;
    }

    .prop-details-filters .row .col-md-4, .geography-filters .row .col-md-4 {
      padding-right: 0;
    }

    .geography-filters {
      @media (min-width: 1200px) {
        padding: 0 0 0 35px;
      }

      @media (max-width: 1199px) {
        margin-top: 15px;
      }
    }
  }

  .reporting-period-row {
    padding-top: 15px;
  }
}

.boldFT {
    font-weight: bolder;
}

.iconSpacing {
    padding-right: 5px;
}

.form-control.overrides {
  width: 300px;
  height: initial
}

.cursor-fix {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.k-pdf-export .chartHeader,
.k-pdf-export .chartFooter {
  display: inline;
}
