@import '../_variables.scss';

.compbuilder-edit {
  .text-input-form {
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    
    span {
      min-width: 110px;
      margin: auto;
      font-weight: bold;
      flex: 0 1;
    }
    input {
      margin-left: 5%;
      border-radius: 0;
      flex: 1 0;
    }
  }

  .compset-map {
    .map-wrapper {
      position: relative;
      height: 350px;
    }
    bing-map {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      a img {
        position: absolute;  
        left: -10px;  
        height: 50px;
        width: 50px;
      }
    }

    .bing-map-legend {
      position: absolute;
      z-index: $MapOverlayZIndex;
      background-color: white;
      width: 230px;
      right: 0px;
      bottom: 0px;
      padding-right: 10px;
  
      img {
        height: 20px;
        width: 20px;
      }
  
      #pin-legend {
        padding: 7px 5px;
      }
    }
  }
}
