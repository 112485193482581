@import '../_variables.scss';

property-quality-metrics {
  .section-title {
    color: $PrimaryBlueDark;  
    font-size: 18px; 
    font-weight:normal;   
  }

  #gridScoreAndMeans, #gridSurveyDeficiencies {
    th {
      text-align: center;
      vertical-align: middle;
    }
  }
}