@import '_variables.scss';
html .k-filtercell > .k-operator-hidden {
  padding-right: 0;
  button {
    display: none;
  }
}
html .k-filtercell > span {
  padding-right: 2.5em;
}
html .k-filter-row .k-dropdown-operator {
  right: 0;
}
.k-multiselect-wrap {
  input {
    font-weight: bold;
    color: $PrimaryBlueDark !important;
    padding-left: 7px !important;
    padding-top: 4px !important;
  }
  &::before {
    display: inline-block;
    width: 16px;
    height: 16px;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    text-align: center;
    background-image: url('Bootstrap/sprite.png');
    border-color: transparent;
    background-position: 0 -32px;
    box-sizing: content-box;
    content: '';
    position: absolute;
    z-index: 1000;
    bottom: 7px;
    right: 5px;
  }

  span[title='clear'] {
    display: none !important;
  }
}
li.k-state-focused {
  border: 0 !important;
  background-color: #ebebeb;
  box-shadow: none !important;
}
.form-control,
:not(li).k-state-focused {
  border-color: $KendoLightGray;
  box-shadow: 0 0 0 0 !important;
  border-radius: 0;
}

.form-control:focus {
  border-color: $KendoLightGray;
}
.k-list > .k-item {
  font-weight: bold;
}
.k-autocomplete.k-state-default,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default,
.k-multiselect.k-header {
  border-radius: 0;
}
.k-pdf-export .chartHeader,
.k-pdf-export .chartFooter {
  display: inline;
}
.k-group {
  display: inline-block;
  padding: 2em;
}
.k-grid-header {
  background-color: $PrimaryBlueDark;
  tr > * {
    background-color: $PrimaryBlueDark;
    color: $PrimaryWhite;
  }
  a {
    color: $PrimaryWhite !important;
  }
}
.kendo-grid-header-center {
  .k-grid-header th.k-header {
    text-align: center !important; //header doesn't center align without this
  }
}
.k-grid {
  border: none;
  td {
    border-color: lighten($SecondaryGrayLight, 28) !important;
    padding: 15px;
    a {
      font-weight: 600;
      color: $SecondaryBlueLight;
    }
  }
}
.k-textbox > input,
:not(.k-header).k-autocomplete .k-input,
:not(.k-header) > .k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  margin: 0 5px 0 7px;
  color: $PrimaryBlueDark;
  font-weight: 600;
}
.k-multiselect-wrap .k-input {
  cursor: pointer;
}

.k-grid-header .k-header > .k-link .k-icon.k-i-sort-asc-sm,
.k-grid-header .k-header > .k-link .k-icon.k-i-sort-asc-sm:before {
  content: "\e004";
  color: white;
}
.k-grid-header .k-header > .k-link .k-icon.k-i-sort-desc-sm,
.k-grid-header .k-header > .k-link .k-icon.k-i-sort-desc-sm:before {
  content: "\e006";
  color: white;
} 

.btn,
.k-button {
  border-radius: 0;
}

.k-loading-image {
    display: none;
}

.k-state-disabled {
  border-radius: 0;
}

.k-chart-shared-tooltip-marker {
  display: none;
}

.k-grid .k-button.k-button-icon[title="Clear"]{
  display:none;
}

.k-list-container{
  word-wrap: break-word;
  overflow-x: hidden;
}

.k-datepicker.ng-invalid {
  .k-picker-wrap {
    border-color: red;
  }
}

.k-grid-norecords {
  font-weight: bold;
  font-size: large;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f5f5f5;
}